exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apa-jsx": () => import("./../../../src/pages/apa.jsx" /* webpackChunkName: "component---src-pages-apa-jsx" */),
  "component---src-pages-ekosistem-jsx": () => import("./../../../src/pages/ekosistem.jsx" /* webpackChunkName: "component---src-pages-ekosistem-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontak-jsx": () => import("./../../../src/pages/kontak.jsx" /* webpackChunkName: "component---src-pages-kontak-jsx" */),
  "component---src-pages-mengapa-jsx": () => import("./../../../src/pages/mengapa.jsx" /* webpackChunkName: "component---src-pages-mengapa-jsx" */),
  "component---src-pages-semesta-data-jsx": () => import("./../../../src/pages/semesta-data.jsx" /* webpackChunkName: "component---src-pages-semesta-data-jsx" */),
  "component---src-pages-tentang-jsx": () => import("./../../../src/pages/tentang.jsx" /* webpackChunkName: "component---src-pages-tentang-jsx" */),
  "component---src-pages-tindaklanjut-jsx": () => import("./../../../src/pages/tindaklanjut.jsx" /* webpackChunkName: "component---src-pages-tindaklanjut-jsx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-balikpapan-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/balikpapan.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-balikpapan-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-banda-aceh-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/banda_aceh.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-banda-aceh-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-bandung-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/bandung.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-bandung-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-denpasar-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/denpasar.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-denpasar-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-jambi-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/jambi.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-jambi-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-kupang-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/kupang.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-kupang-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-makassar-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/makassar.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-makassar-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-malang-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/malang.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-malang-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-medan-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/medan.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-medan-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-palu-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/palu.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-palu-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-purbalingga-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/purbalingga.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-purbalingga-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-semarang-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/semarang.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-semarang-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-singkawang-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/singkawang.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-singkawang-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-sumbawa-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/sumbawa.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-sumbawa-mdx" */),
  "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-surabaya-mdx": () => import("./../../../src/templates/EcosystemDetail.jsx?__contentFilePath=/opt/build/repo/src/cities/surabaya.mdx" /* webpackChunkName: "component---src-templates-ecosystem-detail-jsx-content-file-path-src-cities-surabaya-mdx" */)
}

